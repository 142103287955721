'use strict';
angular.module('angular-scroll', []);
/**
 * @ngdoc directive
 * @name angular-scroll.directive:when-visible
 * @restrict A
 *
 * @description
 * Allows method hooks into the detection of when an element is scrolled into or out of view.
 *
 * @example
 * <example module="angular-scroll-animate">
 *   <file name="index.html">
 *     <div class="car" when-visible="animateIn" when-not-visible="animateOut">Broom</div>
 *   </file>
 *   <file name="controller.js">
 *   angular.module('example', []).controller(function($scope) {
 *
 *     $scope.animateIn = function($el) {
 *       $el.removeClass('hidden');
 *       $el.addClass('animated fadeIn');
 *     };
 *
 *     $scope.animateOut = function($el) {
 *      $el.addClass('hidden');
 *       $el.removeClassClass('animated fadeIn');
 *     };
 *   });
 *
 *   </file>
 * </example>
 */
angular.module('angular-scroll', [])
    .factory('inViewport', ['$document', function(){

        return function($el, viewportHeight, delayPercent) {

            var elementBounds = $el[0].getBoundingClientRect();

            var panelTop = elementBounds.top;
            var panelBottom = elementBounds.bottom;

            // pixel buffer until deciding to show the element
            var delayPx = 0;
            if (delayPercent){
                delayPx = delayPercent * elementBounds.height;
            }

            var bottomVisible = (panelBottom - delayPx > 0) && (panelBottom < viewportHeight);
            var topVisible = (panelTop + delayPx <= viewportHeight) && (panelTop > 0);

            if (bottomVisible || topVisible) {
                return true;
            }

            return false;
        };
    }])
    .directive('scrollPosition', ['$document', '$window', function($document, $window) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                var current;
                current = $window.pageYOffset;
                angular.element($window).on('scroll', function () {
                    var ref;
                    if ($window.pageYOffset < 25 || $window.innerHeight + $window.pageYOffset + 250 >= document.body.offsetHeight ) {
                        scope.scrolled = false;
                    } else {
                        scope.scrolled = (ref = this.pageYOffset > current) != null ? ref : { 'true': false };
                    }
                    current = this.pageYOffset;
                    return scope.$apply();
                });
            }
        };
    }])
    .directive('whenVisible', ['$document', '$window', 'inViewport',
        function($document, $window, inViewport) {

            function isFunction(functionToCheck) {
                var getType = {};
                return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
            }

            var determineWhereElementIsInViewport =
                function($el, viewportHeight, whenVisibleFn, whenNotVisibleFn, delayPercent, scope) {

                    var elementBounds = $el[0].getBoundingClientRect();

                    var panelTop = elementBounds.top;
                    var panelBottom = elementBounds.bottom;

                    var isElmView = inViewport($el, viewportHeight, delayPercent);
                    if ($el.data('hidden') && isElmView){
                        if (isFunction(whenVisibleFn)) whenVisibleFn($el, scope);
                        $el.data('hidden', false);
                    }
                    // scrolled out from scrolling down or up
                    else if (!($el.data('hidden')) && (panelBottom < 0 || panelTop > viewportHeight)) {
                        if (isFunction(whenNotVisibleFn)) whenNotVisibleFn($el, scope);
                        $el.data('hidden', true);
                    }
                };

            return {
                restrict: 'A',
                scope: {
                    whenVisible: '&?',
                    whenNotVisible: '&?',
                    delayPercent: '=?'
                },

                controller: ['$scope', function(scope) {
                    if (!scope.whenVisible || !angular.isFunction(scope.whenVisible())) {
                        throw new Error('Directive: angular-scroll \'when-visible\' attribute must specify a function.');
                    }

                    if (scope.whenNotVisible && !angular.isFunction(scope.whenNotVisible())) {
                        throw new Error('Directive: angular-scroll \'when-not-visible\' attribute must specify a function.');
                    }

                    if (scope.delayPercent) {

                        var delayPercent = parseFloat(scope.delayPercent);

                        if ((Number.isNaN(delayPercent)) || (delayPercent < 0 || delayPercent > 1)) {
                        throw new Error('Directive: angular-scroll \'delay-percent\' attribute must be a decimal fraction between 0 and 1.');
                        }
                    }
                }],

                link: function(scope, el, attributes) {

                    var delayPercent = attributes.delayPercent || 0.1; // lower = more eager to hide / show, higher = less eager

                    var scrollTimeout = null;  // global for any pending scrollTimeout

                    var scrollHandler = function() {

                        //console.log('You scrolled to position:' + window.pageYOffset ) ;

                        var document = $document[0].documentElement;
                        var viewportHeight = document.clientHeight;

                        determineWhereElementIsInViewport(el, viewportHeight,
                            scope.whenVisible(), scope.whenNotVisible(), delayPercent, scope);

                    };

                    var unbindDocumentEvents = angular.element(window).on('scroll', function(){
                        if (scrollTimeout) {
                            clearTimeout(scrollTimeout);
                            scrollTimeout = null;
                        }
                        scrollTimeout = setTimeout(scrollHandler, 22);
                    });

                    var unbindWindowEvents = angular.element(window).on('resize orientationchange', scrollHandler);

                    //scope.$on('$destroy', unbindDocumentEvents);
                    //scope.$on('$destroy', unbindWindowEvents);
                    scope.$on('$destroy', function () {
                        clearTimeout(scrollTimeout);
                        unbindDocumentEvents;
                        unbindWindowEvents;
                    });

                    // initialise
                    el.data('hidden', true);
                    scope.$evalAsync(scrollHandler);
                }
            };
        }
    ]);
