/**
 * Created by novakiam on 17/09/15.
 */

/* Filters */

angular.module('sybanFilters', [])

    .filter('referenceFilter',function(event){
        event.preventDefault();
        return function(input, filterCriteria){
            var out = [];

            if (!filterCriteria){
                return input;
            }

            angular.forEach(input, function(reference){

                if(reference.status === filterCriteria){
                    out.push(reference);
                }
            });
            return out;
        };
    });
