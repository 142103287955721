/**
 * Created by novakiam on 17/09/15.
 */

/* Controllers */
var cmsBaseUrl = 'https://api.cubo.fsinteractive.cz';
var cmsCardsUrl = 'https://api.cubo.fsinteractive.cz/api/pub/cards?filter[subject]=5cb48da631860e4dd20aeec9';

// var cmsBaseUrl = 'http://localhost:7090';
// var cmsCardsUrl = 'http://localhost:7090/api/pub/cards?filter[subject]=5cb48da631860e4dd20aeec9';

var sybanControllers = angular.module('sybanControllers', ['ngAnimate', 'ngSanitize']);

//---------------
// Controllers
//---------------
sybanControllers.controller('mainCtrl',['$scope', '$timeout', '$http', '$location', 'smoothScroll',
    function($scope, $timeout, $http, $location, smoothScroll) {
        $scope.pageClass = 'page-home';
        $scope.bodyClass = "";
        $scope.navMenu = "";
        $scope.search = "";
        $scope.homeTemplate = 'partials/home.html';
        $scope.showServiceDetail = false;
        $scope.showHideMap = true;
        $scope.serviceDetail = 'constructs';
        $scope.email = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
        $scope.post = {};

        $scope.displayServiceDetail = function($event, detailName) {

            if ($scope.showServiceDetail && detailName == $scope.serviceDetail){
                $scope.serviceDetail = '';
                $scope.showServiceDetail = false;
            }else{
                $scope.showServiceDetail = true;
                $scope.serviceDetail = detailName;
            }
            return $scope.serviceDetail;
        };

        $scope.go = function(href){
            $location.path(href);
        };

        $scope.navTo = function(elem){
            $scope.closeAll();
            var element = document.getElementById(elem);
            if (!element){
                $timeout(function () {
                    var element = document.getElementById(elem);
                    var options = {
                        duration: 700,
                        easing: 'easeInQuad',
                        offset: 0,
                    };
                    smoothScroll(element, options);
                }, 750);
                $location.path("#/#"+elem);
            }
        };

        $scope.closeAll = function(){
            $scope.bodyClass = "";
            $scope.navMenu = "";
            $scope.search = "";
        };

        $scope.toggleForm = function() {

            if ($scope.bodyClass == "")
                $scope.bodyClass = "contact-form-open";
            else{
                $scope.bodyClass = "";
            }
        };

        /**
         * Show Hide navigation window
         **/
        $scope.toggleNav = function() {
            if ($scope.navMenu === "close"){
                $scope.navMenu = "";
            }else{
                $scope.navMenu = "close";
            }

            if ($scope.bodyClass === "nav-main-open"){
                $scope.bodyClass = 'hide-nav';
                $timeout(function() {
                    $scope.bodyClass = "";
                }, 500);
            }else{
                $scope.bodyClass = 'nav-main-open';
            }
        };

        $scope.sendEmail = function(from, subject, body) {

            $scope.message = "Sending E-mail...Please wait";
            $http({
                method: 'GET',
                url: window.location.protocol + "//" + window.location.host + "/sendMail",
                params: {
                    from: escape(from),
                    subject: encodeURIComponent(subject),
                    text: encodeURIComponent(body)
                }
            }).then(function successCallback(response) {
                if (response.data == "sent") {
                    $scope.message = "Email has been sent!";
                    $scope.post = {
                        email: from,
                        subject: "",
                        body:""
                    };
                    $timeout(function () {
                        $scope.message = "";

                    }, 10000);
                }

            }, function errorCallback(response) {
                console.log('get', response);
                $scope.message = "Email error!";
                $timeout(function () {
                    $scope.message = "";
                }, 15000);
            });
        };

        $scope.clearMailFields = function(){
            $scope.post = {
                email:'',
                subject:'',
                body:''
            };
        };

        /**
         * ESC key-press functionality
         *  -  Toggle body to show/hide NAV
         *  -  Toggle toggle-nav button for NAV
         **/
        $scope.keyCallback = function($event) {
            $event.preventDefault();
            $scope.closeAll();
            $scope.search = 'hidden';
            $scope.toggleNav();
        };

        $scope.animateElementIn = function($el) {
            if ($el.hasClass('hidden')) {
                $el.removeClass('hidden');
                $el.addClass('animated slideInUp');
            }
        };

        $scope.animateElementOut = function($el) {
            if ($el.hasClass('animated')) {
                $el.removeClass('animated slideInUp');
            }
        };


        $timeout(function () {
            var root = document.getElementsByTagName( 'html' )[0];
            root.setAttribute( 'class', 'loaded' );
        }, 2200);

    }
]);

sybanControllers.controller('sliderCtrl', ['$scope',
    function ($scope) {
        var width = window.innerWidth > window.innerHeight ? window.innerWidth :  window.innerHeight;
        $scope.imageSize= 'lg';

        $scope.rslides = [
            {
                image: '/images/slides/' + $scope.imageSize + '/radnice_jablonec.jpg',
                title: 'Syban',
                text_top:'',
                text_bottom:'Jsme již 20 let s vámi',
                style: '',
                href: '#/anniversary',
            },
            {
                image: '/images/slides/' + $scope.imageSize + '/nastavba_schenker.jpg',
                title: '',
                text_top:"",
                text_bottom:"Nabízíme řešení všech staveb i celého procesu",
                style: '',
                href: '#/#services',
            },
            {
                image: '/images/slides/' + $scope.imageSize + '/bd_bernardova.jpg',
                title: '',
                text_top:"",
                text_bottom:"Zateplení objektů",
                style: '',
                href: '#/#references',
            },{
                image: '/images/slides/' + $scope.imageSize + '/mestsky_ad_kosmonosy.jpg',
                title: '',
                text_top:"",
                text_bottom:"Historické fasády",
                style: '',
                href: '#/#references',
            },{
                image: '/images/slides/' + $scope.imageSize + '/pecovatelsky_dum_studenec.jpg',
                title: '' ,
                text_top:"",
                text_bottom:"Výstavba nových objektů",
                style: '',
                href: '#/#references',
            },
            {
                image: '/images/slides/' + $scope.imageSize + '/sokolovna_kruh.jpg',
                title: '',
                text_top:"",
                text_bottom:"Komplexní rekonstrukce",
                style: 'dark',
                href: '#/#references',
            },
            {
                image: '/images/slides/' + $scope.imageSize + '/rodinny_dum_mnisek.jpg',
                title: '',
                text_top:"",
                text_bottom:"... stavme spolu",
                style: 'dark',
                href: '/#about',
            }];
    }
]);

sybanControllers.controller('referenceListCtrl',
    function ($scope, $http) {
        $scope.cmsBaseUrl = cmsBaseUrl;
        $scope.referenceListTemplate = 'partials/referenceList.html';
        $scope.referenceFilterName = "myRefFilter";

        $http.get(
            cmsCardsUrl + '&filter[collect.code]=reference', {
                headers: {
                    'Application-ID': 'ed53b0b8-16b9-43a4-b8a4-4fc6ec0dba90'
                },
                cache: true}
        ).success(function(data) {
            $scope.references = [];
            if (data && data.data){

                $scope.references = data.data.sort( function(a, b) {
                    return (a.meta['zakladni-udaje']['ref_datum'] > b.meta['zakladni-udaje']['ref_datum']) ? 1 :
                        (a.meta['zakladni-udaje']['ref_datum'] === b.meta['zakladni-udaje']['ref_datum']) ?
                            ((a.meta['zakladni-udaje']['ref_datum'] > b.meta['zakladni-udaje']['ref_datum']) ? 1 : -1) : -1
                });
            }
        });

        $scope.orderProp = '';
    }
);

sybanControllers.controller('referenceDetailCtrl', ['$scope', '$routeParams', '$http', '$sce',
    function($scope, $routeParams, $http) {
        $scope.cmsBaseUrl = cmsBaseUrl;
        $scope.pageClass = 'page-about';
        $scope.showHideMap = false;

        var width = window.innerWidth;
        if(width > 1200) {
            $scope.imageSize= 'l';
        }else if(width > 800) {
            $scope.imageSize= 'm';
        }else{
            $scope.imageSize= 's';
        }

        $http.get(
            cmsCardsUrl + '&filter[collect.code]=reference', {
                headers: {
                    'Application-ID': 'ed53b0b8-16b9-43a4-b8a4-4fc6ec0dba90'
                },
                cache: true}
        ).success(function(data) {
            if (data.data){

                $scope.references = data.data;
                angular.forEach($scope.references, function(reference, index){
                    if (reference._id == $routeParams.refId){

                        $scope.referenceDetail = reference;
                        // $scope.referenceDetail.textContent.description = $sce.trustAsHtml($scope.referenceDetail.textContent.description);
                        $scope.referenceDetail.imageList = [];
                        angular.forEach($scope.referenceDetail.images, function(image, index){
                            var imageDetail = {
                                image: cmsBaseUrl + image.url.replace('bl', 'pub') + "/l",
                                title: '',
                                text_top: image.description || '',
                                text_bottom:'',
                                style: ''
                            };
                            $scope.referenceDetail.imageList.push(imageDetail);
                        });

                        if ($scope.references.length >= 1){

                            if ($scope.references.length === 1){
                            }
                            else if (index > 0 && index < $scope.references.length-1){
                                $scope.prevRefId = $scope.references[index - 1]._id;
                                $scope.nextRefId = $scope.references[index + 1]._id;
                            }else if(index === 0){
                                $scope.prevRefId = $scope.references[$scope.references.length - 1]._id;
                                $scope.nextRefId = $scope.references[1]._id;
                            }else {
                                $scope.prevRefId = $scope.references[index - 1]._id;
                                $scope.nextRefId = $scope.references[0]._id;
                            }
                        }
                    }
                });
            }
        });
    }]);

sybanControllers.controller('aboutCtrl', ['$scope', '$http',
    function ($scope, $http) {
        $scope.pageClass = 'page-about';
        $scope.bodyClass = "";
        $scope.navMenu = "";
        $scope.search = "";

        $http.get(
            cmsCardsUrl + '&filter[collect.code]=zamestnanci', {
                headers: {
                    'Application-ID': 'ed53b0b8-16b9-43a4-b8a4-4fc6ec0dba90'
                },
                cache: true}
        ).success(function(data) {
            if (data && data.data){
                $scope.persons = data.data;
            }
        });
    }
]);

sybanControllers.controller('blogCtrl',
    function ($scope, $http) {
        $scope.cmsBaseUrl = cmsBaseUrl;
        $scope.pageClass = 'page-blog';
        $scope.blogTemplate = 'partials/blog.html';
        $http.get(
            cmsCardsUrl + '&filter[collect.code]=aktuality', {
                headers: {
                    'Application-ID': 'ed53b0b8-16b9-43a4-b8a4-4fc6ec0dba90'
                },
            cache: true}
        ).success(function(data) {
            if (data && data.data){

                $scope.articleList = data.data.sort( function(a, b) {
                    return (a.meta['zakladni-udaje']['akt_datum'] > b.meta['zakladni-udaje']['akt_datum']) ? 1 :
                        (a.meta['zakladni-udaje']['akt_datum'] === b.meta['zakladni-udaje']['akt_datum']) ?
                            ((a.meta['zakladni-udaje']['akt_datum'] > b.meta['zakladni-udaje']['akt_datum']) ? 1 : -1) : -1
                });
            }

        });
        $scope.orderProp = 'date';
    }
);

sybanControllers.controller('blogDetailCtrl', ['$scope','$routeParams', '$http',
    function ($scope, $routeParams, $http) {

        $scope.pageClass = 'page-blog';

        var width = window.innerWidth;
        if(width > 1600) {
            $scope.imageSize= 'xl';
        }else if(width > 1280) {
            $scope.imageSize= 'l';
        }else if(width > 768) {
            $scope.imageSize= 'md';
        }else{
            $scope.imageSize= 'sm';
        }


        $http.get(
            cmsCardsUrl + '&filter[collect.code]=aktuality', {
                headers: {
                    'Application-ID': 'ed53b0b8-16b9-43a4-b8a4-4fc6ec0dba90'
                },
                cache: true}
        ).success(function(data) {
            $scope.articleList = data.data;

            angular.forEach($scope.articleList, function(article, index){
                if (article._id == $routeParams.id){

                    $scope.articleDetail = article;
                    $scope.articleDetail.imageList = [];
                    angular.forEach($scope.articleDetail.images, function(image, index){
                        var imageDetail = {
                            image: cmsBaseUrl + image.url.replace('bl', 'pub') + "/l",
                            title: '',
                            text_top: image.description,
                            text_bottom:'',
                            style: ''
                        };
                        $scope.articleDetail.imageList.push(imageDetail);
                    });

                    if ($scope.articleList.length > 1){
                        if (index > 0 && index < $scope.articleList.length-1){
                            $scope.prevId = $scope.articleList[index - 1]._id;
                            $scope.nextId = $scope.articleList[index + 1]._id;
                        }else if(index === 0){
                            $scope.prevId = $scope.articleList[$scope.articleList.length - 1]._id;
                            $scope.nextId = $scope.articleList[index + 1]._id;
                        }else{
                            $scope.prevId = $scope.articleList[index - 1]._id;
                            $scope.nextId = $scope.articleList[0]._id;
                        }
                    }
                }
            });

        });
    }
]);

sybanControllers.controller('anniversaryCtrl',
    function ($scope, $http) {
        $scope.pageClass = 'page-about';
        $scope.bodyClass = "";
        $scope.navMenu = "";
        $scope.search = "";


    }
);