/**
 * Created by novakiam on 17/09/15.
 */

//---------------
// Directives
//---------------
angular.module('sybanDirectives', [])
    .directive('googleMap', ['$rootScope', 'Initializer',
        function ($rootScope, Initializer) {

            return {
                restrict: 'AE',
                scope: {
                    mapId: '@id', // map ID
                    lat: '@',     // latitude
                    lng: '@'     // longitude
                },
                link: function ($scope, elem, attrs) {

                    // Check if latitude and longitude are specified
                    if (angular.isDefined($scope.lat) && angular.isDefined($scope.lng) && angular.isDefined($scope.mapId)) {

                        Initializer.mapsInitialized
                            .then(function () {
                                $scope.location = new google.maps.LatLng($scope.lat, $scope.lng);

                                $scope.mapOptions = {
                                    center: $scope.location,
                                    zoom: 16,
                                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                                    disableDoubleClickZoom: true,
                                    zoomControl: true,
                                    draggable: true,
                                    scrollwheel: false
                                };
                                $scope.map = new google.maps.Map(document.getElementById($scope.mapId), $scope.mapOptions);

                                $scope.marker = new google.maps.Marker({
                                    position: $scope.location,
                                    title: 'Syban,&nbsp;a.s.',
                                    subtitle: 'Stavení&nbsp;společnost',
                                    content: '28. října 60/44, 460 01 Liberec 7',
                                    icon: {
                                        url: 'images/icons/pin2.svg',
                                        // This marker is 20 pixels wide by 32 pixels high.
                                        size: new google.maps.Size(40, 40),
                                        // The origin for this image is (0, 0).
                                        origin: new google.maps.Point(0, 0),
                                        // The anchor for this image is the base of the flagpole at (0, 32).
                                        anchor: new google.maps.Point(0, 32)
                                    },
                                    map: $scope.map
                                });


                                $scope.infoWindow = new google.maps.InfoWindow();
                                $scope.infoWindow.setContent('<h3>' + $scope.marker.title + '</h3>' + '<p strong>'+ $scope.marker.subtitle + '</p>' + '<p>'+ $scope.marker.content + '</p>');
                                google.maps.event.addListener($scope.marker, 'click', function(){
                                    $scope.infoWindow.open($scope.map, $scope.marker);
                                });

                                $scope.map.set('styles', [{
                                    "featureType": "all",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "lightness": "15"
                                        }
                                    ]
                                }, {
                                    "featureType": "administrative",
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                        {
                                            "color": "#444444"
                                        }
                                    ]
                                }, {
                                    "featureType": "landscape",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "color": "#f2f2f2"
                                        }
                                    ]
                                }, {
                                    "featureType": "poi",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "visibility": "simplified"
                                        }, {
                                            "saturation": "-68"
                                        }, {
                                            "lightness": "31"
                                        }
                                    ]
                                }, {
                                    "featureType": "poi",
                                    "elementType": "labels",
                                    "stylers": [
                                        {
                                            "visibility": "on"
                                        }
                                    ]
                                },
                                    {
                                        "featureType": "poi",
                                        "elementType": "labels.icon",
                                        "stylers": [
                                            {
                                                "visibility": "simplified"
                                            }
                                        ]
                                    }, {
                                        "featureType": "road",
                                        "elementType": "all",
                                        "stylers": [
                                            {
                                                "saturation": -100
                                            },
                                            {
                                                "lightness": 45
                                            }
                                        ]
                                    }, {
                                        "featureType": "road.highway",
                                        "elementType": "all",
                                        "stylers": [
                                            {
                                                "visibility": "simplified"
                                            }
                                        ]
                                    }, {
                                        "featureType": "road.arterial",
                                        "elementType": "label.icons",
                                        "stylers": [
                                            {
                                                "visibility": "on"
                                            }
                                        ]
                                    }, {
                                        "featureType": "transit",
                                        "elementType": "all",
                                        "stylers": [
                                            {
                                                "visibility": "off"
                                            }
                                        ]
                                    }, {
                                        "featureType": "road.local",
                                        "elementType": "geometry.stroke",
                                        "stylers": [
                                            {
                                                "color": "#d7d7d7"
                                            }
                                        ]
                                    }, {
                                        "featureType": "transit",
                                        "elementType": "all",
                                        "stylers": [
                                            {
                                                "visibility": "off"
                                            }
                                        ]
                                    }, {
                                        "featureType": "water",
                                        "elementType": "all",
                                        "stylers": [
                                            {
                                                "color": "#77c1bc"
                                            },
                                            {
                                                "visibility": "on"
                                            },
                                            {
                                                "lightness": "13"
                                            }
                                        ]
                                    }, {
                                        "featureType": "water",
                                        "elementType": "geometry.fill",
                                        "stylers": [
                                            {
                                                "lightness": "31"
                                            }
                                        ]
                                    }, {
                                        "featureType": "water",
                                        "elementType": "labels.text",
                                        "stylers": [
                                            {
                                                "color": "#3d3b38"
                                            },
                                            {
                                                "visibility": "simplified"
                                            },
                                            {
                                                "lightness": "20"
                                            }
                                        ]
                                    }
                                ]);
                        });
                    }
                }
            }
        }])
    .directive('ngEsc', function () {
        return function (scope, element, attrs) {
            element.bind("keydown keypress keyup", function (event) {
                if(event.which === 27) {
                    event.preventDefault();
                    scope.$apply(function (){
                        scope.$eval(attrs.ngEsc);
                    });
                }
            });
        };
    })

    .directive('slider', function($timeout){

        return {
            restrict: 'AE',
            replace: true,
            scope: {
                slides: '=',
            },
            link: function(scope, elem, attrs) {
                scope.slider_timeout;
                scope.direction = 'left';
                scope.currentIndex = 0;
                scope.setCurrentSlideIndex = function (index) {
                    scope.direction = (index > scope.currentIndex) ? 'left' : 'right';
                    scope.currentIndex = index;
                    return false;
                };

                scope.isCurrentSlideIndex = function (index) {
                    return scope.currentIndex === index;
                };
                scope.isCurrentDirectionLeft = function () {
                    return scope.direction === 'right';
                };
                scope.prevSlide = function () {
                    scope.direction = 'right';
                    scope.currentIndex = (scope.currentIndex > 0) ? --scope.currentIndex : scope.slides.length - 1;
                    scope.startTimeout();
                };
                scope.nextSlide = function () {
                    scope.direction = 'left';
                    scope.currentIndex = (scope.currentIndex < scope.slides.length - 1) ? ++scope.currentIndex : 0;
                    scope.startTimeout();
                };

                scope.startTimeout = function () {
                    scope.stopTimeout();
                    scope.slider_timeout = $timeout(function (){
                        scope.nextSlide()
                    },15000);
                };
                scope.stopTimeout = function () {
                    $timeout.cancel(scope.slider_timeout);
                };

                scope.$on('$destroy',function(){
                    scope.stopTimeout();
                });

                (function(){
                    scope.startTimeout();
                })();


            },
            templateUrl: 'templates/fs-slider.html',
        };
    }).directive('listbox', function() {
        return {
            restrict: 'AE',
            replace: true,
            scope: {
                boxes: '=',
                cmsBaseUrl: '='
            },
            link: function (scope, elem, attrs) {
                scope.isActive = function (index) {
                    return scope.currentIndex === index;
                };
                scope.imageSize = function(){
                    var width = window.innerWidth;
                    if(width > 1600) {
                        return "xl"
                    }else if(width > 1280) {
                        return "lg";
                    }else if(width > 640) {
                        return "md";
                    }else{
                        return "sm";
                    }
                };
                scope.referenceFilter = '';

            },
            templateUrl: 'templates/fs-listbox.html',
        };
    });





