/**
 * Created by novakiam on 17/09/15.
 */

//---------------
// Services
//---------------
var sybanServices = angular.module('sybanServices', ['ngResource']);

sybanServices.factory('ContentItems', ['$resource', function($resource){

    var contentItemsResource = $resource('/:itemtype/:action/:id',{
        itemtype: '@itemtype',
        id: '@id'
    }, {
        list: {
            method: 'GET',
            params: {
                action: 'list',
                itemtype: '@itemtype'
            },
            isArray: true
        }

    });
    return contentItemsResource;
}]);

// Lazy loading of Google Map API
sybanServices.factory('Initializer', function($window, $q){

    //Google's url for async maps initialization accepting callback function
    var asyncUrl = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAtgG2xWTM58PUiIEFv8uyWlIJlo2CicjU&callback=',
        mapsDefer = $q.defer();

    //Callback function - resolving promise after maps successfully loaded
    $window.googleMapsInitialized = mapsDefer.resolve;

    //Async loader
    var asyncLoad = function(asyncUrl, callbackName) {
        var script = document.createElement('script');
        script.src = asyncUrl + callbackName;
        document.body.appendChild(script);
    };
    //Start loading google maps
    asyncLoad(asyncUrl, 'googleMapsInitialized');

    //Usage: Initializer.mapsInitialized.then(callback)
    return {
        mapsInitialized : mapsDefer.promise
    };
});

sybanServices.factory('Reference', ['$resource',
    function($resource){
        return $resource('reference/:referenceId', {}, {
            query: {method:'GET', params:{referenceId:'reference'}, isArray:true}
        });
    }
]);
