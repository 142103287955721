(function (angular) {
    'use strict';

    var app = angular.module('app', [
        'ngRoute',
        'ngResource',
        'ngTouch',
        'sybanAnimations',
        'sybanControllers',
        'sybanDirectives',
        'sybanFilters',
        'sybanServices',
        'ngCookies',
        'infinite-scroll',
        'angular-scroll',
        'smoothScroll',
        'angularViewportWatch'
    ]);

    app.config([
        '$compileProvider',
        function ($compileProvider) {
            $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|chrome-extension):/);
        }]
    );

    //---------------
    // Routes
    //---------------
    app.config(['$routeProvider',
        function ($routeProvider) {

            $routeProvider
                .when('/', {
                    templateUrl: 'templates/home.html',
                    controller: 'mainCtrl'
                }).when('/about', {
                    templateUrl: 'templates/about.html',
                    controller: 'aboutCtrl'
                }).when('/reference/:refId', {
                    templateUrl: 'templates/referenceDetail.html',
                    controller: 'referenceDetailCtrl'
                }).when('/blog',{
                    templateUrl: 'templates/blog.html',
                    controller: 'blogCtrl'
                }).when('/blog/:id', {
                    templateUrl: 'templates/blogDetail.html',
                    controller: 'blogDetailCtrl'
                }).when('/anniversary', {
                    templateUrl: 'templates/anniversary.html',
                    controller: 'anniversaryCtrl'
                }).otherwise({
                    redirectTo: '/'
                });
        }
    ]);


    var httpGetAsync = function(theUrl, callback)
    {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function() {
            if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
                callback(xmlHttp.responseText);
        };
        xmlHttp.open("GET", theUrl, true); // true for asynchronous
        xmlHttp.send(null);
    };
})(angular);